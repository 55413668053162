import React from 'react';

import {
  Divider, List, ListItem, ListItemText, Grid, Typography, Paper,
} from '@material-ui/core';

// Styles
import { stylesDetail } from './css/themes'

// Internationalization
import { useTranslation } from 'react-i18next';

// Videos
import 'video-react/dist/video-react.css';
import { Player, BigPlayButton } from 'video-react';
import SimpleBarComponent from './plot_bar_chart'

const useStylesDocumetation = stylesDetail

//Organism component
export default function DocumentationComponent(props) {
  const classes = useStylesDocumetation();
  const { t } = useTranslation();

  return (
    <div>
      <Grid container>
        <Grid item xs={2}>
          <div className={classes.fixedList}>
            <List>
              <ListItem className={classes.listItemTitle} button component='a' href={props.location.pathname + '#overview'}>
                <ListItemText primary={
                  <Typography type='body2' style={{ fontSize: 20 }}>{t('documentation.overview')}</Typography>
                } />
              </ListItem>
            </List>

            <List>
              <ListItem className={classes.listItemTitle} button component='a' href={props.location.pathname + '#features'}>
                <ListItemText primary={
                  <Typography type='body2' style={{ fontSize: 20 }}>{t('documentation.features')}</Typography>
                } />
              </ListItem>
              <Divider />
              <ListItem className={classes.listItem} button component='a' href={props.location.pathname + '#tree_docs'}>
                <ListItemText primary={t('navbar.tree')} />
              </ListItem>
              <Divider />
              <ListItem className={classes.listItem} button component='a' href={props.location.pathname + '#table_docs'}>
                <ListItemText primary={t('navbar.table')} />
              </ListItem>
              <Divider light />
              <ListItem className={classes.listItem} button component='a' href={props.location.pathname + '#charts_docs'}>
                <ListItemText primary={t('navbar.charts')} />
              </ListItem>
              <Divider />
              <ListItem className={classes.listItem} button component='a' href={props.location.pathname + '#organism_search_docs'}>
                <ListItemText primary={t('navbar.advance_organism_search')} />
              </ListItem>
              <Divider />
            </List>

            <List>
              <ListItem className={classes.listItemTitle} button component='a' href={props.location.pathname + '#faq'}>
                <ListItemText primary={
                  <Typography type='body2' style={{ fontSize: 20 }}>{t('documentation.faq')}</Typography>
                } />
              </ListItem>
            </List>


            <List>
              <ListItem className={classes.listItemTitle} button component='a' href={props.location.pathname + '#methodology'}>
                <ListItemText primary={
                  <Typography type='body2' style={{ fontSize: 20 }}>{t('documentation.methodology')}</Typography>
                } />
              </ListItem>
              <Divider />
              <ListItem className={classes.listItem} button component='a' href={props.location.pathname + '#organism_metadata'}>
                <ListItemText primary={t('navbar.organism_metadata')} />
              </ListItem>
              {/**
              <Divider />
              <ListItem className={classes.listItem} button component='a' href={props.location.pathname + '#proteome_metadata'}>
                <ListItemText primary={t('navbar.proteome_metadata')} />
              </ListItem>
               **/
              }
            </List>

          </div>
        </Grid>

        <Grid item xs={10} >

          <span id='overview'></span>
          <Paper style={{ margin: 30, marginRight: '10%', boxShadow: 'none' }}>
            <Typography variant='h4' style={{ marginBottom: 15 }}>
              Overview
            </Typography>
            <SimpleBarComponent />
          </Paper>

          <span id='features'></span>
          <Paper style={{ margin: 30, marginRight: '10%', boxShadow: 'none' }}>
            <Typography variant='h4' style={{ marginBottom: 15 }}>
              Features
            </Typography>
            <span id='tree_docs'></span>
            <Typography variant='h5' style={{ marginTop: 20 }}>
              {'Taxonomy Browser'}
            </Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={5}
            >
              <Grid item xs={6}>
                <div>
                  Search for a specific genome <i>(e.g. Kyrpidia spormannii):</i>
                  <p align='justify'>Navigate through the taxonomic ranks (displayed at the right side of the folders) ranging from domain through strain.
                  At the strain level it is possible to click on each strain to get information of the organism including NCBI ftp direct links and references.</p>
                </div>
              </Grid>

              <Grid item xs={6}>
                <Player>
                  <source src={process.env.PUBLIC_URL + '/videos/tree.mp4'} />
                  <BigPlayButton position="center" />
                </Player>
              </Grid>
            </Grid>

            <span id='table_docs'></span>
            <Typography variant='h5' style={{ marginTop: 20 }}>
              {'DB Browser'}
            </Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={5}
            >
              <Grid item xs={6}>
                <Player>
                  <source src={process.env.PUBLIC_URL + '/videos/table.mp4'} />
                  <BigPlayButton position="center" />
                </Player>
              </Grid>
              <Grid item xs={6}>
                <div>
                  Can obtain all extreme acidophilic organisms a phylum (e.g. Firmicutes):
                  <p align='justify'>By adding the column “phylum” to the displayed columns it is possible to use the filter function to obtain organism from the Firmicutes phylum.
                  The optimum pH column can be filtered using the expressions less than (&#60;), greater than (&#62;) or range
                  expressions e.g. 0-10. Each column can sort
                    the table in ascending or descending order. It is also possible to add more columns and download the table to a csv  (of all columns) file for further analyses. </p>
                </div>
              </Grid>
            </Grid>


            <span id='charts_docs'></span>
            <Typography variant='h5' style={{ marginTop: 20 }}>
              {'Scatter plot'}
            </Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={5}
            >
              <Grid item xs={6}>
                <div>
                  Selecting a dataset of e.g. thermoacidophilic bacteria from isolated source:
                  <p align='justify'>The plot allows custom axis selection with growth conditions such as optimum pH and temperature (ºC).
                  A combination of all domains or just a specific domain like Bacteria can be selected. Options for quality of
                  the genome and source (isolated or non isolated sample) can be used to narrow the desired data. Information about an
                  organism can be selected by clicking the aproprioate dot in the scatter plot.
                  The information can be downloaded as a CSV file. </p>
                </div>
              </Grid>

              <Grid item xs={6}>
                <Player>
                  <source src={process.env.PUBLIC_URL + '/videos/plot.mp4'} />
                  <BigPlayButton position="center" />
                </Player>
              </Grid>
            </Grid>

            <span id='organism_search_docs'></span>
            <Typography variant='h5' style={{ marginTop: 20 }}>
              {'Advanced search'}
            </Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={5}
            >
              <Grid item xs={6}>
                <Player>
                  <source src={process.env.PUBLIC_URL + '/videos/search.mp4'} />
                  <BigPlayButton position="center" />
                </Player>
              </Grid>
              <Grid item xs={6}>
                <div>
                  E.g. Curated dataset of moderate acidophilic Archaea of genus <i>Sulfolobus</i> with complete genomes.
                  <p align='justify'>All taxonomy ranks are available to use as filters. Growth range conditions add the “Can grow at”
                  feature that will search in the range each organism can grow. Detailed options of genome metadata are
                  available to filter. This tool download gives a csv file with all fields available in the database
                     for all the results of the search.</p>
                </div>
              </Grid>
            </Grid>

          </Paper>


          <span id='faq'></span>
          <Paper style={{ margin: 30, marginRight: '10%', boxShadow: 'none' }}>
            <Typography variant='h4' style={{ marginBottom: 15 }}>
              FAQ
            </Typography >
            <Typography variant='h5'>
              Database functions:
            </Typography>
            <Typography >
              <p align='justify'>
                1.- Q: <i>How do I find and download the data of a specific species?</i>
              </p>
              <p align='justify'>
                A: Use the database browser to search for a specific genome. The first and second columns can be used to search for the species and strain. Additional information regarding growth conditions and genomic metadata can be added to the table and downloaded as a tab separated values TSV file that can be imported into a spreadsheet program, e.g. Microsoft Excel.
              </p>
              <p align='justify'>
                2. Q: <i>What is a TSV file?</i>
              </p>
              <p align='justify'>
                A: A TSV file stores a data table in which columns of data are separated by tabs. It can be imported into most spreadsheet programs, which format the data into cells. TSV files can also be exported by spreadsheet programs, which allows the data to be viewed with a basic text editor or word processing program (https://fileinfo.com/extension/tsv).
              </p>
              <p align='justify'>
                3.- Q: <i>How can I parse the TSV file?</i>
              </p>
              <p align='justify'>
                A: The TSV file is ready to be read by any spreadsheet manager software e.g. libre/open office (Linux) and Microsoft Excel. In the case of Excel, use the function “Data” -> “ Split text to columns” and select the delimiter to “tab” to obtain the correct display of the data.
              </p>
              <p align='justify'>
                4.- Q: <i>How can I download the metadata of multiple organisms?</i>
              </p>
              <p align='justify'>
                A: Use the advanced search function. Search by using taxonomic information (from phylum to species), growth condition (pH, temperature) or genomic data (genome size, genome GC%) to get a specific dataset. This feature will display all the genomes according to the given filters and will allow you to download a TSV file with all the data by clicking in “DOWNLOAD TSV”. You can also do this in the scatterplot option. Simply click and drag inside the plot to select all the genomes within the given values, and then click in “DOWNLOAD TSV”.
              </p>
              <p align='justify'>
                5.- Q: <i>How can I download the genomes of multiple organisms?</i>
              </p>
              <p align='justify'>
                A: After downloading the metadata for multiple organisms, copy every “ftp_url” into an internet browser to download the genome manually. If you want to do it automatically, copy all the cells in the column “ftp_url” into a plain text editor (gedit, notepad) and add “/*genomic.gbff.gz” to the end of every line and save it. Then you would need a command such as “wget” to obtain multiple files (webhostface.com/kb/knowledgebase/examples-using-wget/).
              </p>
              <p align='justify'>
                6.-Q: <i>How can I leave out Metagenome Assembled Genomes in the searches?</i>
              </p>
              <p align='justify'>
                A: This can be done by selecting “Isolated” in the “Genome metadata” section of the organism search tab, or unchecking the “Environmental” box at the bottom right in the scatterplot tab. Similarly, if you only want genomes obtained directly from the environment, select the “Non isolated” option in the organism search tab or uncheck the “Isolated” box while checking the “Environmental” box in the scatterplot. We chose the terms “environmental” and “Non isolated” instead of MAGs, as there are genomic methods other than metagenomics that provide us with genomes directly from the environment without laboratory culture, such as Single Cell Genomics.
              </p>
              <p align='justify'>
                7.-Q: <i>Why can’t I find my acidophile in AciDB?</i>
              </p>
              <p align='justify'>
                A. There are several possibilities to explain this:
              <ol type='i'>
                  <li>
                    The genome information was only recently made available and has not yet been entered into AciDB. Check the date of the last update to AciDB. You can contact Gonzalo Neira (gonzalo.neira@ug.uchile.cl) to request that your genome is added.
                  </li>
                  <li>
                    The genome did not pass quality control for completeness and/or lack of significant contamination using CheckM v1.1.2 (Parks, et al. <i>Genome research</i> 25.7 (2015): 1043-1055).
                  </li>
                  <li>
                    Some organisms are incompletely classified. If this is the case for your organism, search for it in “Unclassified”. For example, the genus <i>Methylacidimicrobium</i> is classified in the phylum Verrucomicrobia but lacks a description of class, order and family. Hence, it is sorted into the “unclassified” taxon inside the Verrucomicrobia phylum. Taxa such as the DPANN group, containing the phyla Parvarchaeota and Micrarchaeota, can be found inside the domain Archaea. Similarly, the Diaforarchaea and the DHVE-2 groups, (the latter contains the genus <i>Aciduliprofundum</i>) can be found in “unclassified” in the Euryarchaeota phylum. Candidate taxa are displayed without the “candidatus” tag for simplification.
                  </li>
                  <li>
                    Acid tolerant organisms that can survive low pH conditions for short periods of time (for example <i>Helicobacter</i> pylori or <i>Escherichia coli</i> that survive the low pH of the stomach are not included as they generally have an optimum growth pH higher than 5.
                  </li>
                </ol>
              </p>
              <p align='justify'>
                8.-Q: <i>How can I run BLAST against the organisms in the database?</i>
              </p>
              <p align='justify'>
                The option is not available yet in AciDB. The possibility of downloading a BLAST database of the acidophiles to perform local BLAST searches is planned for a future release of AciDB.
              </p>
              <Typography variant='h5'>
                Database information:
            </Typography>
              <p align='justify'>
                9.-Q: <i>How were the acidophiles identified?</i>
              </p>
              <p align='justify'>
                A: A comprehensive literature search was conducted. Reviews on acidophiles (e.g. Johnson and Hallberg, Res. Microbiol. 154.7 466-473, (2003); Cardenas et. al., “Progress in acidophile genomics.” Acidophiles: life in extremely acid environments (2016): 179-197) were used as starting points. The list of acidophiles was extended by keyword literature searches (e.g. acidophile) in Google Scholar and species description journals (e.g. International Journal of Systematic and Evolutionary Microbiology). Additionally, the JGI pH metadata and NCBI’s BIOSAMPLE database were used to collect genomes that were linked to low pH environments. Organisms from the same family as the previously identified acidophiles were also checked using the NCBI taxonomy browser. They were considered if they had a reported growth pH ≤5. Genomes or MAGs of all accepted acidophiles, were downloaded from the NCBI or JGI databases. At every stage, the bibliography and metadata were consulted to obtain the strain specific pH associated with the genome.
              </p>
              <p align='justify'>
                10.-Q: <i>How was the optimum pH for growth obtained? Why is this information not available for some entries?</i>
              </p>
              <p align='justify'>
                A: The primary sources of information were the published experimental data, in which case we have provided the relevant literature references.  However, there are cases where this information is not available, for example:
              <ol type='i'>
                  <li>
                    Growth pH is reported in the literature as between pH X and pH Y, but no information about optimal pH is provided. In this case, we took the average.
                  </li>
                  <li>
                    The strain was grown at a certain pH but there are no reports whether other pH were tested (no growth range or optimum). In this case, we took this pH.
                  </li>
                  <li>
                    For MAGs (metagenome-assembled genomes) and isolated genomes without explicit growth information, we took the reported environmental pH at the source of the sample as representing the optimal pH. This value should be treated with caution as the optimal pH might be different in the environment from where the organisms originate. For example, organisms taken from a surface acid mine drainage site with a pH of X might have originated (grown) a deep subsurface with a pH of Y.  This is a very problematic issue.
                  </li>
                  <li>
                    Sometimes, no pH growth data of a potential acidophile could be found in the literature. In this case, the genome was included only if the growth pH of a member of the same species was found. The same species was defined by having an ANI (Average Nucleotide Identity) value of ≥95%. If no ANI value was available in the literature, we calculated it using fastANI v1.3 (Jain et. al., <i>Nature communications</i> 9.1 (2018): 1-8).
                  </li>
                </ol>
              </p>
              <p align='justify'>
              11.-Q: <i>How were the values for genome completeness and potential genome contamination obtained?</i> 
              </p>
              <p align='justify'>
              A: Both values were obtained based on the identification of a series of markers specific for each taxa as implemented in CheckM v1.1.2 (Parks, et al. Genome research 25.7 (2015): 1043-1055)
              </p>
              <Typography variant='h5'>
                Database Maintenance:
            </Typography>
              <p align='justify'>
              12.- Q: <i>How often is the database going to be updated?</i>
              </p>
              <p align='justify'>
                A: The database will be updated twice a year.
              </p>
              <p align='justify'>
              13.- Q: <i>What cool new features are there going to be in the future?</i>
              </p>
              <p align='justify'>
              A: In addition to new genome entries, we propose to include more metadata such as the location where the genome was obtained. We are also working on ways to include functional annotations, e.g. via Blast. 
              </p>
              <p align='justify'>
              14.- Q: <i>How can I add my genome into AciDB?</i>
              </p>
              <p align='justify'>
              To maximize and maintain a highly curated database, there is no mechanism available to automatically deposit new genomes.  To deposit a new genome or MAG data information please contact Gonzalo Neira (gonzalo.neira@ug.uchile.cl).  
              </p>
            </Typography >

          </Paper>

          <span id='methodology'></span>
          <Paper style={{ margin: 30, marginRight: '10%', boxShadow: 'none' }}>
            <Typography variant='h4' style={{ marginBottom: 15 }}>
              Methodology
            </Typography>
            <Typography paragraph align='justify'>
              {'AciDB is a curated database of metadata of acidophiles (grow at a pH<5) and their genomes. AciDB includes information of about 600 genomes of Bacteria, \
              Archaea and Eukarya. Growth information is presented as both optimal and range of temperature and pH when explicitly reported. Otherwise, the optimal was calculated \
              as the midpoint of the range. If no data were available, the environmental temperature of the strain was reported. For metagenome assembled genomes (MAGs), \
              sampling data is provided. All metadata from the genomes reported in the database comes from public databases (NCBI or JGI) and from extensive literature search. \
              More details on the database construction can be found in the publication '}
              <a href='https://doi.org/10.1093/bioinformatics/btaa638'>link</a>.
            </Typography>
            {/** 
            <Typography paragraph align='justify'>
              {'For proteomic data, the genomes’ predicted proteins were obtained from their respective databases, if present. Genomes without existing predicted proteomes \
              were annotated with PROKKA v1.13.3 [ref]. The genome quality was assesed using CheckM v1.0.12 [ref] using the standard lineage workflow and the “reduced tree” \
              option.'}
            </Typography>
            <Typography paragraph align='justify'>
              {'For every genome in this database a series of associated metadata was collected to provide clarity on these genomes’ characteristics and to increase the \
              amount of possible analysis able to be done on these genomes.'}
            </Typography>
            */}
          </Paper>

          <span id='organism_metadata'></span>
          <Paper style={{ margin: 50, marginRight: '10%', boxShadow: 'none' }}>
            <Typography variant='h5' style={{ marginTop: 20, marginBottom: 20 }}>
              Organism metadata
          </Typography>
            <Typography variant='h6' style={{ marginTop: 20 }}>
              General identifiers
            </Typography>
            <List>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Strain (s): '}</b>{'This is the strain designation given to either the sequenced monoclonal culture (strain) or MAG  (Metagenome-Assembled Genome). One genome can have several strain designations. At least one strain designation was included per genome.'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Organism Name: '}</b>{'This is the genus and species of the strain. If no species or no genus is known, the most specific taxonomic classification is given (e.g Alicyclobacillus sp., Acidobacteria bacterium).'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Isolated: '}</b>{'“Yes” if the genome comes from a monoclonal culture, “No” if it was assembled from a metagenome (MAG) or from a single cell metagenomic project.'}
                </Typography>
              </ListItem>
            </List>

            <Typography variant='h6' style={{ marginTop: 20 }}>
              Genome accessions and annotation
            </Typography>
            <List >
              <ListItem >
                <Typography align='justify'>
                  <b>{'Source of genome: '}</b>{'The genome repository where this genome was obtained (GenBank, RefSeq, JGI or “Other”).'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Annotation: '}</b>{'The method of annotation or source of predicted proteins of the genome (GenBank, RefSeq, JGI or “PROKKA”).'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Accession id: '}</b>{'The Accession id for obtaining the genome in the repository mentioned in “Source of genome”'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'FTP URL: '}</b>{'The URL to the repository with FTP access'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Biosample: '}</b>{'NCBI accession id of the sample this microorganism was sequenced from.'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Bioproject: '}</b>{'NCBI accession id of the sequencing project of the microorganism.'}
                </Typography>
              </ListItem>
            </List>

            <Typography variant='h6' style={{ marginTop: 20 }}>
              Genomic features
            </Typography>
            <List>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Assembly level: '}</b>{'“Complete” if it’s a closed genome, “Draft” if it is not a closed genome.'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Sequencing Date: '}</b>{'This is the date when the genome sequence was first fully submitted. It is not the last modification date or the project starting date.'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Genome Size: '}</b>{'This is the size of the genome in Mb. It only considers the actual genome sequence and not associated plasmids or organelles.'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'GC percentage: '}</b>{'The percentage of guanine + cytosine nucleotides in the sequenced genome.'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Number of ORFs: '}</b>{'This is the number of predicted open reading frames in the genomic sequence.'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Genome completeness: '}</b>{'The completeness level of the genome in percentage as predicted by the software CheckM. A value close to 100% indicates a nearly closed genome.'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Genome contamination: '}</b>{'The contamination level of the genome in percentage as predicted by the software CheckM. A 5% contamination indicates that about 5% of the genome sequence probably belongs to another organism.'}
                </Typography>
              </ListItem>
            </List>

            <Typography variant='h6' style={{ marginTop: 20 }}>
              Growth features
            </Typography>

            <List>
              <ListItem>
                <Typography align='justify'>
                  <b>Caveats for reporting pH and temperature data</b>
                  <br />
                  <br />
                  pH, temperature optima and ranges are be subject to the following caveats:
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  (i) Reports for metagenomes give the values for in situ sites where the DNA was
                  isolated, which are not necessarily the environments from where the organisms
                  originated.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  (ii) The values of pH and temperature reported for cultures are dependent on the growth
                  medium selected. For example, <i>Acidithiobacillus ferrooxidans</i> has been reported to
                  grow optimally at 35°C or at 37°C and from pH 2.6 to pH 5 depending on the source
                  of energy used in the growth medium.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  (iii) Acidophiles present a particular problem because they are capable of altering both the
                  temperature and pH of their environments. Some acidophiles can alter their
                  environment from 4°C up to 37°C and from pH 6 down to pH 2. Therefore, pH and
                  temperature reports depend on where the information was derived.
                </Typography>
              </ListItem>
            </List>

            <List>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Optimum temperature: '}</b>{'The optimal temperature for growth of this strain, if known. If only growth range has been reported, the optimum temperature was calculated as the midpoint of the range. If no optimal or growth range has been reported, the observed growth range was considered as the optimal. If none of these data are available, the environmental temperature of the strain was reported. For MAGs, only environmental data was used.'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Minimum temperature: '}</b>{'The minimum recorded temperature that the organism can grow. If only one growth temperature is known (optimum or observed), a range of 10°C was assumed around the optimum, unless the only data available is environmental temperature.'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Maximum temperature: '}</b>{'Analog for minimum (see previous comment) but for the highest recorded value.'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Optimum pH: '}</b>{'The optimal pH for growth of this strain, if known. If no optimal pH has been reported, the same criteria was used as for optimum temperature.'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Minimum pH: '}</b>{'The minimum recorded pH this organism can grow at. If only one growth pH is known, a range of +/- one pH unit was assumed around the optimum.'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Maximum pH: '}</b>{'Analog for minimum but for the highest recorded pH (see previous comment).'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Temperature source: '}</b>{'Either “Optimum”, “Range”, “Growth” or “Environment” depending on how the optimum temperature was calculated.'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'pH source: '}</b>{'Either “Optimum”, “Range”, “Growth” or “Environment” depending on how the optimum pH was calculated.'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='justify'>
                  <b>{'Temperature and pH confidence: '}</b>{'The reliability of the information about growth temperature and pH classified as  “High” (detailed lab experiments), “Medium” (unspecific metadata or only observed growth) or “Low” (only T and pH data of environmental sample available) or “Unreliable” (indirect information about the possible temperature and pH of the environment from which the organism was isolated.'}
                </Typography>
              </ListItem>
            </List>

            <Typography variant='h6' style={{ marginTop: 20 }}>
              Reference
            </Typography>
            <List>
              <ListItem>
                <Typography align='justify'>
                  {'The reference to the publication of the genome sequencing project or the temperature and/or pH information of the genome. The publication is in APA format, or provides information from a culture collection, biosample or bioproject. Several references can be found per genome.'}
                </Typography>
              </ListItem>
            </List>
          </Paper>

          {
            /** 
            <span id='proteome_metadata'></span>
            <Paper style={{ margin: 50, marginRight: '10%', boxShadow: 'none', marginTop: 50 }}>
              <Typography variant='h5' style={{ marginTop: 20, marginBottom: 20 }}>
                Proteome metadata
            </Typography>
              <Typography paragraph align='justify'>
                {'The predicted proteome of all the genomes present in this database was analyzed with several softwares to determine their physicochemical and biochemical characteristics. This allows to search for functions among the acidophiles’ genomes and to provide increasing amounts data for statistical analysis. The available characteristics are:'}
              </Typography>
              <Typography variant='h6' style={{ marginTop: 20 }}>
                General characteristics
              </Typography>
              <List>
                <ListItem>
                  <Typography align='justify'>
                    <b>{'Protein length: '}</b>{'The length of the proteins in number of aminoacids'}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography align='justify'>
                    <b>{'Molecular Weight: '}</b>{'The mass of the protein in Daltons'}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography align='justify'>
                    <b>{'Isoelectric point: '}</b>{'The pH at which the polipeptide has a theoretical charge of zero (isoelectrical). This was calculated with the sofvtware IPC v1.0 with the ProMoST pKa dataset.'}
                  </Typography>
                </ListItem>
              </List>
              <Typography variant='h6' style={{ marginTop: 20 }}>
                Subcellular localization
              </Typography>
              <List>
                <ListItem>
                  <Typography align='justify'>
                    <b>{'Psort: '}</b>{'The subcellular localization of the protein as predicted by the software PsortB v3.0. The options are: “c” cytoplasmatic, “im” inner membrane, “p” periplasm, “om” outer membrane, “w” cell wall, “e” exported and “u” unknown. '}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography align='justify'>
                    <b>{'SignalP: '}</b>{'The presence or absence of a translocation signal peptide, which might be a TAT, SEC or a LIPO (Lipoprotein) signal peptide, as predicted by the software SignalP v5.0b.'}
                  </Typography>
                </ListItem>
              </List>
              <Typography variant='h6' style={{ marginTop: 20 }}>
                Transmembrane predictions
              </Typography>
              <List>
                <ListItem>
                  <Typography align='justify'>
                    <b>{'TMHMM: '}</b>{'The number of transmembrane regions as well as the topology as predicted by the software TMHMM v2.0c. The letter “i” means the first transmembrane segment has its N-terminal end towards the cytoplasm. The letter “o” is the other way around.'}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography align='justify'>
                    <b>{'HMMTOP: '}</b>{'The number transmembrane regions as well as the topology as predicted by the software HMMTOP. The symbology is the same as in TMHMM.'}
                  </Typography>
                </ListItem>
              </List>
              <Typography variant='h6' style={{ marginTop: 20 }}>
                Functional annotation
              </Typography>
              <List>
                <ListItem>
                  <Typography align='justify'>
                    <b>{'COG: '}</b>{'The id of the “Cluster of Ortholog Groups” (COG) functional domain annotation of the protein as predicted by the web tool '}<a href='http://eggnog-mapper.embl.de'>{'EggNog mapper v5.0'}</a>{' with default values.'}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography align='justify'>
                    <b>{'COG category: '}</b>{'The category of biological functions where this protein’s COG domain belongs to. See '}<a href='http://clovr.org/docs/clusters-of-orthologous-groups-cogs/'>{'http://clovr.org/docs/clusters-of-orthologous-groups-cogs/'}</a>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography align='justify'>
                    <b>{'Pfam:  '}</b>{'The id of the “Protein family” (Pfam) functional domain annotation of the protein as predicted by the software'}<a href='https://pfam.xfam.org/'>{' Pfam scan v1.6'}</a>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography align='justify'>
                    <b>{'EC number: '}</b>{'The id of the enzymatic reaction this protein might perform, if it was predicted to be an enzyme by EggNog mapper v5.0. See '}<a href='https://biocyc.org/'>{'https://biocyc.org/'}</a>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography align='justify'>
                    <b>{'KEGG ko: '}</b>{'The id of the KEGG Orthology molecular function of the protein as predicted by EggNog mapper v5.0.'}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography align='justify'>
                    <b>{'KEGG pathway: '}</b>{'The id of the pathway in the KEGG pathway collection this protein participates in as predicted by EggNog mapper v5.0.'}
                  </Typography>
                </ListItem>
              </List>
            </Paper>
            **/
          }

        </Grid>
      </Grid >

    </div >
  );
}


