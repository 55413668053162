import React from 'react';
import {
  Grid, Typography, IconButton
} from '@material-ui/core';
import GitHubIcon from '@material-ui/icons/GitHub';


// Styles
import { stylesFooter } from './css/themes'

// Internationalization
import { useTranslation } from 'react-i18next';

// loading component for suspense fallback
const useStylesFooter = stylesFooter


export default function Footer() {
  const { t } = useTranslation();
  const classes = useStylesFooter();
  //Phantom div for padding
  return (
    <div style={{ height: 15 }}>
      <div>
      </div>
      <div className={classes.footer}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('footer.desc')}
              <IconButton color="inherit" href="https://gitlab.com/Hawkline451/acidb">
                <GitHubIcon />
              </IconButton>
            </Typography>
          </Grid>

        </Grid>
      </div>
    </div>
  );
}
