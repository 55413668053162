export const config = {
    'BASE_URL': 'https://api.acidb.cl',
    'API_ORGANISMS': 'https://api.acidb.cl/api/organism/',
    'API_ORGANISM_DETAIL': 'https://api.acidb.cl/api/organism_detail/',
    'API_SEARCH_VALUES': 'https://api.acidb.cl/api/search_list/',
    'API_SIMPLE_PLOT': 'https://api.acidb.cl/api/simple_plot_data/',
    'API_ADVANCE_SEARCH' : 'https://api.acidb.cl/api/search/?',
    'API_PROTEIN_SEARCH' : 'https://api.acidb.cl/api/protein_search/?'
}

/*
export const config = {
    'BASE_URL': 'http://127.0.0.1:8000',
    'API_ORGANISMS': 'http://127.0.0.1:8000/api/organism/',
    'API_ORGANISM_DETAIL': 'http://127.0.0.1:8000/api/organism_detail/',
    'API_SEARCH_VALUES': 'http://127.0.0.1:8000/api/search_list/',
    'API_SIMPLE_PLOT': 'http://127.0.0.1:8000/api/simple_plot_data/',
    'API_ADVANCE_SEARCH' : 'http://127.0.0.1:8000/api/search/?',
    'API_PROTEIN_SEARCH' : 'http://127.0.0.1:8000/api/protein_search/?'
}
*/
